<template>
  <div class="album-article">
    <LogoTitle :msg="detail.contentTitle"></LogoTitle>
    <div class="article">
      <div class="public-time">
        发布时间 ：{{
          detail.contentDatetime ? detail.contentDatetime.substr(0, 10) : ""
        }}
      </div>
      <div class="album-swiper">
        <swiper :options="albumSwiperOption" ref="albumSwiper">
          <!-- slides -->
          <template v-if="!isLoading">
            <swiper-slide v-for="item in detail.contentImg" :key="item.id">
              <img :src="item.url" alt="" />
            </swiper-slide>
          </template>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="active-text">
          <span>{{ activeSlide + 1 }}</span
          >/{{ detail.contentImg ? detail.contentImg.length : 0 }}
        </div>
      </div>
      <!-- <div class="intro">{{ detail.contentDescription }}</div> -->
      <div class="intro" v-html="detail.contentDetails"></div>
      <div class="pages">
        <div class="pre" v-if="preObj">
          <span @click="nextAlbum(0)">上一篇：{{ preObj.contentTitle }}</span>
        </div>
        <div class="next" v-if="nextObj">
          <span @click="nextAlbum(1)">下一篇：{{ nextObj.contentTitle }}</span>
        </div>
      </div>
    </div>

    <div class="more-list" v-if="list.length > 0">
      <LogoTitle msg="活动图集"></LogoTitle>
      <div class="go-more">
        <span @click="goMore">查看更多></span>
      </div>
      <div class="list">
        <AlbumCard
          v-for="item in list"
          :info="item"
          @tagClick="tagClick"
          :key="item.id"
        ></AlbumCard>
      </div>
    </div>
  </div>
</template>

<script>
let vm = null
export default {
  data () {
    return {
      activeSlide: 0, //轮播图下标
      albumSwiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange () {
            vm.activeSlide = this.activeIndex
          }
        }
      },
      detail: '',//详情
      id: '',
      nextObj: null,//下一篇
      preObj: null,//上一篇
      list: [],
      isLoading: false //加载中
    }
  },
  computed: {
    albumSwiper () {
      return this.$refs.albumSwiper.$swiper
    },
  },
  created () {
    vm = this
    this.init()
  },
  methods: {
    init () {
      this.id = this.$route.query.id;
      this.getDetail();
    },
    /**
     * @description: 上一篇、下一篇
     * @param type:0上一篇  1下一篇
     */
    nextAlbum (type) {
      let id = type ? this.nextObj.id : this.preObj.id
      this.$router.push({ path: '/news/album/albumArticle', query: { id: id } })
      this.id = id
      this.isLoading = true;
      this.getDetail()
    },
    /**
     * @description: 获取详情
     * @param  {id:this.id}
     * @return {*}
     */
    getDetail () {
      let params = {
        id: this.id,
        remarks: 0 //是否需要获取上下篇文章 0-需要 1或不传-不需要
      }
      this.$api.getDetail(params).then((res) => {
        let { info, next, pre } = res.data.data
        this.detail = info;
        this.detail.contentImg = JSON.parse(this.detail.contentImg)
        this.nextObj = next;
        this.preObj = pre;
        document.title = info.contentTitle;
        this.getList();
        //滑动到第一个
        this.albumSwiper.slideTo(0);
        this.isLoading = false;
      })
    },
    tagClick (data) {
      this.tagsId = data;
      this.currentPage = 1;
      this.getFilterList();
    },
    //标签筛选
    getFilterList () {
      let params = {
        categoryId: this.detail.categoryId,
        categoryType: '1',
        pageNo: 1,
        pageSize: 4,
        tagsId: this.tagsId
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },

    /**
    * @description: 项目动态列表
    */
    getList () {
      let params = {
        id: this.detail.id,
        categoryId: this.detail.categoryId,
        pageNo: 1,
        pageSize: 4,
      }
      this.$api.getContentTagsList(params).then(res => {
        this.list = res.data.data.rows;
      })
    },
    goMore () {
      this.$router.push({ path: '/news/album/albumList' })
    }
  },
  watch: {
    '$route.query.id' () {
      this.init()
    }
  }
}
</script>

<style scoped lang='scss'>
.album-article {
  .article {
    .public-time {
      font-size: 14px;
      color: #666666;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .album-swiper {
      position: relative;
      width: 1000px;
      //   height: auto;
      margin: 0 auto;
      .swiper-button-prev {
        left: -82px;
      }
      .swiper-button-next {
        right: -82px;
      }
      img {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        max-width: 100%;
        // height: auto;
        display: block;
        max-height: 610px;
        margin: 0 auto;
      }
      .active-text {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
        color: #999;
        span {
          color: #e50113;
          font-weight: bold;
        }
      }
    }
    .intro {
      font-size: 14px;
      color: #666;
      padding: 40px 0;
      border-bottom: 1px solid #ebedf0;
    }
    .pages {
      padding: 20px 0;
      color: #666;
      display: flex;
      > div {
        flex: 1;
        span {
          cursor: default;
          &:hover {
            color: $main-color;
          }
        }
      }
      .next {
        text-align: right;
      }
    }
  }
  .more-list {
    margin-top: 40px;
    .logo-title {
      margin-bottom: 0;
    }
    .go-more {
      margin-top: -24px;
    }
    .list {
      margin-top: 35px;
    }
    .album-card {
      margin-left: 0;
      margin-right: 25px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>